<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio prenotazione</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <div class="richiesta">
                    <div class="field">
                        <div class="title">Data inserimento richiesta</div>
                        <div class="value">
                            {{ dateFormat(prenotazione.prenotazioni_automezzi_creation_date) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Data ed ora inizio</div>
                        <div class="value">
                            {{ dateFormatWithMinutes(prenotazione.prenotazioni_automezzi_data_ora_inizio) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Data ed ora fine</div>
                        <div class="value">
                            {{ dateFormatWithMinutes(prenotazione.prenotazioni_automezzi_data_ora_fine) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Automezzo</div>
                        <div class="value">
                            {{ prenotazione.automezzi_marca + " - " + prenotazione.automezzi_modello }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Luogo destinazione</div>
                        <div class="value">
                            {{ prenotazione.prenotazioni_automezzi_luogo_destinazione ? prenotazione.prenotazioni_automezzi_luogo_destinazione : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Km effettuati</div>
                        <div class="value">
                            {{ prenotazione.prenotazioni_automezzi_km_effettuati ? prenotazione.prenotazioni_automezzi_km_effettuati : "-" }}
                        </div>
                    </div>
                    <div class="field_motivazione">
                        <div class="title">Motivazione</div>
                        <div class="value">
                            {{ prenotazione.prenotazioni_automezzi_motivazione ? prenotazione.prenotazioni_automezzi_motivazione : "-" }}
                        </div>
                    </div>
                </div>

                <div class="nuova_prenotazione" style="display:none">
                    <div class="prenotazione_button" @click="openModal">Inserisci dati aggiuntivi</div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    modalController,
} from "@ionic/vue";

import { defineComponent, onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { arrowBackOutline } from "ionicons/icons";

import moment from "moment";

import { dateFormat, dateFormatWithMinutes } from "@/services/utils";
import { openToast } from "@/services/toast";

import ModalModificaPrenotazione from "@/components/automezzi/ModalModificaPrenotazione.vue";

export default defineComponent({
    name: "PrenotazioneDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
    },
    setup(props, context) {
        const route = useRoute();
        const id = route.params.id; //richiesta_id by route params
        const router = useRouter();
        const loading = ref(false);
        const prenotazione = ref([]);

        //console.log(props.data);
        prenotazione.value = { ...props.data };
        //console.log(prenotazione.value);

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss(prenotazione.value);
        };

        /**
         * ! Close modal after form data submit
         */
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                console.log(res);
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * ! Open modal to edit prenotazione
         */
        async function openModal() {
            const modal = await modalController.create({
                component: ModalModificaPrenotazione,
                componentProps: {
                    data: prenotazione.value,
                },
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    prenotazione.value = { ...detail.data };
                    console.log(detail);
                    openToast("Prenotazione modificata con successo", "toast_success");
                }
            });
            return modal.present();
        }

        return {
            id,
            loading,
            dateFormat,
            dateFormatWithMinutes,
            prenotazione,
            arrowBackOutline,
            closeModal,
            openModal,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.base_text {
    font-size: 14px;
}

.pb-0 {
    padding-bottom: 0;
}

.bg-primary {
    background: var(--ion-color-primary, #3880ff) !important;
    padding: 8px;
    color: #ffffff;
}

.card_title {
    margin: 0;
    padding: 0;
    font-size: 18px;
}

ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}

.description_title {
    color: var(--ion-color-primary, #3880ff) !important;
    margin: 10px 0;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new richiesta btn */
    padding: 16px;
    min-height: 100%;
    background: #f8fafc;
}

.richiesta {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}

.richiesta .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}

.richiesta .field:last-of-type {
    border-bottom: 0;
    margin-bottom: 0;
}
.richiesta .field_motivazione {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px 6px;
}
.field .title,
.field_motivazione .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field_motivazione .title {
    margin-bottom: 8px;
}
.field .value,
.field_motivazione .value {
    font-size: 14px;
}

.value .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.value .badge_success {
    background-color: #22c55e;
}
.value .badge_warning {
    background-color: #f97316;
}
.value .badge_danger {
    background-color: #ef4444;
}

.nuova_prenotazione {
    width: 100%;
    padding-top: 16px;
    display: flex;
    justify-content: center;
}
/* .nuova_prenotazione_container {
  width: 100%;
  position: fixed;
  right: 0;
  bottom: 0;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
} */
.prenotazione_button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}
ion-button {
    --color: #ffffff;
}
</style>
